'use client'

import Link from 'next/link'
import { useRouter } from 'next/navigation'

const ErrorPage = () => {
  const router = useRouter()
  const onClickRefresh = () => router.refresh()
  return (
    <div className="flex h-screen min-w-[360px] items-center justify-center bg-white">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto">
          <div className="flex flex-col items-center justify-center gap-7">
            <div className="w-55 md:w-60">
              <img src="https://d35ai18pny966l.cloudfront.net/course/500_img.png" alt="500" />
            </div>
            <div className="flex flex-col">
              <p className="text-center text-2xl font-semibold">
                일시적인 오류로
                <br className="block md:hidden" /> 요청사항을 수행하지 못했습니다.
              </p>
              <span className="mt-1.5 text-center text-label-neutral">
                잠시 후 다시 시도해주세요.
                <br />
                같은 오류가 반복될 경우 관리자에게 문의해주세요.
              </span>
              <div className="flex items-center justify-center gap-1.5">
                <Link
                  href="https://likelion.channel.io/"
                  className="mt-6 inline-flex items-center justify-center self-center rounded-lg border border-primary px-10 py-2.5 text-primary-normal"
                >
                  <span className="text-base font-semibold">1:1 문의하기</span>
                </Link>
                <button
                  onClick={onClickRefresh}
                  className="mt-6 inline-flex items-center justify-center self-center rounded-lg bg-primary px-10 py-2.5 text-white"
                >
                  <span className="text-base font-semibold">다시 시도하기</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
